import React, { useState } from "react";
import { useAuth } from "./services/context/AuthContext";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/home/Home";
import Contato from "./components/contato/Contato";
import "./App.css";
import "./assets/styles/Reset.css";
import { AuthProvider } from "./services/context/AuthContext";
import Perfil from "./components/perfil/Perfil";
import Login from "./components/login/Login";
import Menu from './components/shared/Menu';

function App() {
  const main = process.env.REACT_APP_MAIN;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    navigate(main);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <div className="App">
      {isAuthenticated || user ? (
        <>
          <Header />
          <div className="home-container body-wrapper">
            <div class="home-dashboard-menu">
              <Menu
                toggleSidebar={toggleSidebar}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
                <Routes>
                  <Route path={main} element={<Home />} />
                  <Route path={main + "perfil/"} element={<Perfil handleLogout={handleLogout} />} />
                </Routes>
          </div>

          <Footer />
        </>
      ) : (
        <>
          <div class="body-wrapper">
            <div class="body-container">
              <Login onLogin={handleLogin} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
