import React, { useState } from "react";
import "./Painel.css";
import ListaAplicacoes from "../probono/AplicacoesEditalProBono2024";
import Contato from "../contato/Contato";
function Painel() {
  const [showAplicacoesEditalProBono2024, setShowAplicacoesEditalProBono2024] = useState(false);

  const toggleAplicacoes = () => {
    setShowAplicacoesEditalProBono2024(!showAplicacoesEditalProBono2024);
  };

  return (
    <div className="painel-container">
        <div className="painel-header">
            <h3>Painel</h3>

              <p>Se tiver alguma dúvida ou precisar de assistência, não hesite em contatar a equipe de suporte.</p>
        </div>
      <div class="painel-content-container">
          <div className="painel-child"> 

          <h2>Edital Pro Bono 01/2024</h2>
          <button className="main-btn toggle-btn" onClick={toggleAplicacoes}>{showAplicacoesEditalProBono2024 ? ('Esconder ') : ('Ver' )} aplicantes do Edital Pro Bono 2024</button>

          {showAplicacoesEditalProBono2024 && <ListaAplicacoes />}
          </div>
          <div className="painel-child"> <Contato /> </div>
        <div className="painel-child"> Novidades em breve... </div>
      </div>
    </div>
  );
}

export default Painel;
