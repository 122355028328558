import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();
const userFunction = process.env.REACT_APP_USER_FUNCTION;

export function AuthProvider({ children }) {
  
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token' + userFunction);
    const userStored = localStorage.getItem('userId'  + userFunction);

    if (token) {
      setUser(userStored);
    }
  }, []);

  const login = (userId) => {
    setUser(userId);
  };

  const logout = () => {
    localStorage.removeItem('userId'  + userFunction);
    localStorage.removeItem('token'  + userFunction);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}


