import React, { useState } from "react";
import "./Home.css";
import Painel from "./Painel.js";
import Menu from "../shared/Menu.js";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="home-container">

      <Painel />

    </div>
  );
}

export default Home;
