import React, { useState } from 'react';
import { logout } from '../../services/auth/AuthUtils';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/context/AuthContext';

const Logout = ({ onLogout }) => {
  
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logout: logoutAuth } = useAuth();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      logout(navigate);
      logoutAuth();
      onLogout();
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleLogout}>
        <button className='main-btn' type='submit'>Logout</button>
        {error}
      </form>
    </div>
  );
};

export default Logout;

