// authUtils.js

const API_URL = process.env.REACT_APP_API;
const userFunction = process.env.REACT_APP_USER_FUNCTION;
const prefix = '/api/auth';

const main = process.env.REACT_APP_MAIN;


export async function login(email, password, navigate) {
  try {
    const response = await fetch( API_URL + prefix + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.status === 401) {
      throw new Error('Email e/ou senha estão incorretos.');
    } else if (!response.ok) {
      throw new Error('Falha na autenticação');
    }

    const data = await response.json();

    localStorage.setItem('token' + userFunction, data.access_token);
    localStorage.setItem('userId' + userFunction, data.user.id);

    return data.userId;

  } catch (error) {
    throw new Error('Erro durante o login: ' + error.message);
  }
}

export function logout(navigate) {
  localStorage.removeItem('token' + userFunction);
  localStorage.removeItem('userId' + userFunction);
  navigate(main);
}

export async function validateToken() {
    try {

    const token = localStorage.getItem('token' + userFunction);

      const response = await fetch( API_URL + prefix + '/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
  
      if (!response.ok) {
        throw new Error('Token inválido');
      } else{
        //sucesso
      }
  
    } catch (error) {
      throw new Error('Erro na validação do token: ' + error.message);
    }
  }

export async function signup( email, password, navigate) {

    try {
      const response = await fetch(API_URL + prefix +  '/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        
      });
  
      if (!response.ok) {
        throw new Error('Falha ao criar conta');
      }
  
      const data = await response.json();
  
      localStorage.setItem('token' + userFunction, data.access_token);
      localStorage.setItem('userId' + userFunction, data.user.id);

      navigate('/');

      return (data.user.id);

    } catch (error) {
      throw new Error('Erro durante a criação da conta: ' + error.message);
    }
  }
  
