import React, { useEffect, useState } from 'react';
import './Contato.css';

const ContactSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibility, setVisibility] = useState({});

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch('https://jacquesgomes.com.br/gb/ethos-contato-index');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSubmissions(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const toggleVisibility = (origin) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [origin]: !prevVisibility[origin]
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const groupByOrigin = (submissions) => {
    const origins = [
      'https://rips.ethosbrasil.org/',
      'https://consultoria.ethosbrasil.org/'
    ];

    return origins.map((origin) => ({
      origin,
      submissions: submissions.filter((submission) => submission.origem === origin)
    }));
  };

  const groupedSubmissions = groupByOrigin(submissions);

  return (
    <div className="container">
      <h1>Formulários de Contato</h1>
      {groupedSubmissions.map((group) => (
        <div key={group.origin} className="origin-section">
          <h2 className="origin-title" onClick={() => toggleVisibility(group.origin)}>
            <div>Origem: {group.origin}</div>
            <div>{visibility[group.origin] ? '-' : '+'}</div>
          </h2>
          {visibility[group.origin] && (
            <>
              {group.submissions.length > 0 ? (
                <ul className="submission-list">
                  {group.submissions.map((submission) => (
                    <li key={submission.id} className="submission-item">
                      {submission.nome && <p><strong>Nome:</strong> {submission.nome}</p>}
                      {submission.email && <p><strong>Email:</strong> {submission.email}</p>}
                      {submission.telefone && <p><strong>Telefone:</strong> {submission.telefone}</p>}
                      {submission.historico && <p><strong>Histórico:</strong> {submission.historico}</p>}
                      {submission.alvos && <p><strong>Alvos:</strong> {submission.alvos}</p>}
                      {submission.qualidadeAlvos && <p><strong>Qualidade dos Alvos:</strong> {submission.qualidadeAlvos}</p>}
                      {submission.nomeAlvos && <p><strong>Nome dos Alvos:</strong> {submission.nomeAlvos}</p>}
                      {submission.cpfCnpjAlvos && <p><strong>CPF/CNPJ dos Alvos:</strong> {submission.cpfCnpjAlvos}</p>}
                      {submission.interesses && submission.interesses.length > 0 && (
                        <p><strong>Interesses:</strong> {submission.interesses.join(', ')}</p>
                      )}
                      {submission.infoAdicional && <p><strong>Informações Adicionais:</strong> {submission.infoAdicional}</p>}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="empty-message">Nenhuma submissão encontrada para esta origem.</p>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ContactSubmissions;
