import React, { useState, useEffect } from 'react';
import './Probono.css';
import Modal from './ModalAplicacao';

const ListaAplicacoes = () => {
  const [aplicacoes, setAplicacoes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAplicacao, setSelectedAplicacao] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://jacquesgomes.com.br/gb/form-pro-bono');
        const data = await response.json();
        setAplicacoes(data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = (aplicacao) => {
    setSelectedAplicacao(aplicacao);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='lista-probono'>
      <h3>Lista de Aplicações</h3>
      <ul>
        {aplicacoes.map((aplicacao) => (
          <li key={aplicacao.id}>
            <p className='probono-aplicante-nome'><strong>Nome:</strong> {aplicacao.nomeCompleto} </p>
            <p className='probono-aplicante-descricao'>
              <strong>Descrição: </strong>
              {aplicacao.descricao.substring(0, 200)}{' '}
              {aplicacao.descricao.length > 200 && <span>...</span>}
            </p>
            <button className='main-btn' onClick={() => openModal(aplicacao)}>Detalhes</button>
          </li>
        ))}
      </ul>

      <Modal showModal={showModal} selectedAplicacao={selectedAplicacao} closeModal={closeModal} />
    </div>
  );
};

export default ListaAplicacoes;
