import React, { useRef, useEffect } from 'react';

const Modal = ({ showModal, selectedAplicacao, closeModal }) => {
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef]);

    if (!showModal) return null;

    return (
        <div className="modal">
            <div className="modal-content" ref={modalRef}>
                <span className="close" onClick={closeModal}>&times;</span>
                <p><strong>Nome:</strong> {selectedAplicacao.nomeCompleto}</p>
                <p><strong>Descrição:</strong> {selectedAplicacao.descricao}</p>
                <p><strong>Profissão:</strong> {selectedAplicacao.profissao}</p>
                <p><strong>Email:</strong> {selectedAplicacao.email}</p>
                <p><strong>Telefone:</strong> {selectedAplicacao.telefone}</p>
                <p><strong>Endereço:</strong> {selectedAplicacao.endereco}</p>
                <p><strong>Ligação:</strong> {selectedAplicacao.ligacao}</p>
                <p><strong>Número do Processo:</strong> {selectedAplicacao.numeroProcesso}</p>
                <p><strong>Renda Mensal:</strong> {selectedAplicacao.rendaMensal}</p>
                <p><strong>Advocacia Privada:</strong> {selectedAplicacao.advocaciaPrivada}</p>
            </div>
        </div>
    );
};

export default Modal;
