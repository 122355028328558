import React, { useState } from 'react';
import { login } from '../../services/auth/AuthUtils';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/context/AuthContext';
import './Login.css';
import SignupForm from './SignUp';
const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login: loginAuth } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {

      const userId = await login(email, password);
      await loginAuth(userId);
      onLogin();
    } catch (error) {
      console.error('Erro durante o login:', error.message);
    }
  };

  return (
    <div className='login-container'>
      <div className='login-header'>
        <i class="uil uil-padlock"></i>
        <h2>Entrar</h2>
      </div>
      <form onSubmit={handleLogin}>
        <label>
          Email
          <br></br>
          <input
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Senha
          <br></br>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type='submit'>Entrar</button>
      </form>
    </div>
  );
};

export default LoginForm;

