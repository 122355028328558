import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './services/context/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
  <AuthProvider>

  <React.StrictMode>
    <App />
  </React.StrictMode>

  </AuthProvider>
      </Router >
);

