import React from 'react';
import { useAuth } from '../../services/context/AuthContext';
import './Perfil.css';
import Logout from '../login/Logout';

const Perfil = ({ handleLogout }) => {
  const { user } = useAuth();

  return (
    <div className='perfil-container'>
      <div className="profile-container">
        <div className="profile-header">
          <h2>Perfil de Usuário</h2>
        </div>
        <div className="profile-info">
<p>Novidades em breve...</p>
        </div>
      </div>
      <div className="logout-btn-perfil" >
        <Logout onLogout={handleLogout} />
      </div>
    </div>
  );
};

export default Perfil;

