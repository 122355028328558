// Menu.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = ({ toggleSidebar, isOpen, setIsOpen }) => {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const manualRouting = (address) => {
    navigate(address);
    toggleSidebar();
  };

  const main = process.env.REACT_APP_MAIN;

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames='fade'>
        <div>
          <div className='sidebar' ref={sidebarRef} style={{ width: isOpen ? '160px' : '45px', transition: 'width 0.5s ease' }}>

            <div className={`menu-toggle-container ${isOpen ? 'expanded' : 'minimized'}`}>

              <button className="menu-toggle" onClick={toggleSidebar}>
                {isOpen ? (<i className='uil uil-arrow-left' />): (<i className = 'uil uil-arrow-right' />)}
                  <span >Recolher Menu</span>
              </button>
              
              <Link to={main}> <i className='uil uil-home' /><span>Início</span></Link>
              <Link to={main + 'perfil/'}> <i className='uil uil-user' /><span>Perfil</span></Link>
            </div>
          </div>
          <div className='curtain'></div>
        </div>
      </CSSTransition>

    </>
  );
};

export default Menu;


