import React from 'react';
import './Footer.css';


const Footer = () => {

  return (
    <div className='footer__container'>
      <div className='copy__container'>
        <div className='copy'>
          {' '}
          <p>
            Desenvolvido por{' '}
            <a href='https://jacquesgomes.com.br/portfolio/'>Jacques Gomes</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;


