// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../../services/context/AuthContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  const main = process.env.REACT_APP_MAIN;
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='header-wrapper'>
      <div className='header-container'>

        <div className='logo'>
          <Link to={main} alt='home'>
            <h1><i className='uil uil-home'> </i>Adm GB</h1>
          </Link>
        </div>

        <div className='header-btns-container'>
         <Link to={main + 'perfil'}>Minha conta</Link>
         
        </div>
      </div>

    </div>
  );
};

export default Header;


